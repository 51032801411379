import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import TitleBlock from "../components/TitleBlock"
import PdfButton from "../components/PdfButton"
import { Link } from "gatsby"

export default function EthicalCode() {
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Certificaciones", to: "/certificacion" },
    { label: " Código Ético y Declaración de Honorabilidad " },
  ]
  return (
    <Layout>
      <Hero image="/imgs/certificaciones-hero.jpg" text="Certificaciones" />
      <div className="container950">
        <Breadcrumb path={path} />
        <TitleBlock
          title="Código Ético y Declaración de Honorabilidad"
          icon="far fa-edit"
        />
        <p className="EthicalCode-text">
          <Link className="EthicalCode-link" to="https://www.efpa.es/docs/codigo_etico.pdf">
            El Código Ético de la asociación
          </Link>
          , establece los estándares de conducta personal y profesional que han
          de cumplir los miembros de la asociación. Al igual que en el resto de
          las delegaciones nacionales de EFPA, en EFPA España se establece un
          Comité Deontológico que se encarga de evaluar aquellos casos de
          incumplimiento del Código Ético y otros casos en que se cuestione la
          conducta de los miembros de la Asociación.
        </p>
        <p className="EthicalCode-text">
          La Asociación vela por que todos sus miembros cumplan los estándares
          mencionados en el Código Ético, para así mejorar la opinión que tienen
          los clientes, el sector de servicios financieros y el público en
          general.
        </p>
        <p className="EthicalCode-text">
          A su vez, los Miembros empleados pueden estar sujetos a normas y
          requisitos específicos como consecuencia del contrato laboral.
        </p>
        <PdfButton className="mb-2" text="Firmar Código Ético" />
        <PdfButton className="mb-2" text="Declaración de Honorabilidad" />
      </div>
    </Layout>
  )
}
