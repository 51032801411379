import React from "react"
import Proptypes from "prop-types"
import { Link } from "gatsby"
import HtmlParser from "react-html-parser"

export default function PdfButton(props) {
  const { className, text, link, isExternal, parseText } = props

  const renderLink = () => {
    if (!isExternal) {
      return (
        <Link to={link} className="PdfButton-link">
          {text}
        </Link>
      )
    }

    return (
      <a
        className="PdfButton-link"
        href={link}
        target="_blank"
        rel="noreferrer"
      >
        {parseText ? HtmlParser(text) : text}
      </a>
    )
  }

  return (
    <div className={`PdfButton ${className}`}>
      <i className="far fa-file-pdf"></i>
      {renderLink()}
    </div>
  )
}

PdfButton.propTypes = {
  className: Proptypes.string,
  text: Proptypes.string,
  link: Proptypes.string,
  parseText: Proptypes.bool,
}

PdfButton.defaultProps = {
  className: "",
  text: "",
  link: "",
  isExternal: false,
}
